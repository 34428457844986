.wrapper {
  & > form {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
}

.header {
  min-height: calc(2 * var(--space-xs) + var(--base-line-height));
  margin-bottom: var(--space-xxs);
  padding: var(--space-xxxs) 0;
}
