.callout {
  box-sizing: border-box;
  position: relative;
  z-index: var(--z-index-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(var(--container-max-width) - (2*var(--space)));
  max-width: 100%;
  min-height: 64px;
  margin-right: auto;
  margin-bottom: var(--space-xs);
  margin-left: auto;
  padding: var(--space-xs) var(--space) var(--space-xs) 54px;
  border: var(--callout-alert-color) solid 1px;
  border-radius: var(--border-radius-lg);
  background-color: #FFFFFF;
  color: var(--primary-text-color);

  @media (width <= 1290px) {
    width: calc(100vw - (2*var(--space)) - 18px);
  }

  @media (--device-mobile) {
    width: calc(100vw - (2*var(--space)));
  }
}

[data-theme="dark"] .callout {
  background-color: var(--secondary-background-color);
}

.callout--success {
  --callout-alert-color: var(--success-state-color);
  --callout-alert-icon: var(--callout-alert-icon-success);
}

.callout--info {
  --callout-alert-color: var(--info-state-color);
  --callout-alert-icon: var(--callout-alert-icon-info);
}

.callout--warning {
  --callout-alert-color: var(--warning-state-color);
  --callout-alert-icon: var(--callout-alert-icon-warning);
}

.callout--error {
  --callout-alert-color: var(--error-state-color);
  --callout-alert-icon: var(--callout-alert-icon-error);
}

.callout--primary {
  --callout-alert-color: var(--primary-accent-color);
  --callout-alert-icon: var(--callout-alert-icon-primary);
}

.callout::after,
.callout::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-hide);
  border-radius: var(--border-radius-lg);
}

.callout::after {
  mask-image: var(--callout-alert-icon);
  mask-repeat: no-repeat;
  mask-position: 18px 19px;
  background-color: var(--callout-alert-color);
}

.callout::before {
  background-color: var(--callout-alert-color);
  opacity: var(--opacity-xxs);
}

.callout hr {
  width: 100%;
  height: 0;
}
