/* .grecaptcha-badge {
  right: auto !important;
  left: 0;
  width: 70px !important;
  transition: width var(--animation-duration) ease-in-out !important;

  &:hover {
    width: 256px !important;
  }
} */

/* Hide the badge as there is custom element to show the terms and conditions message */
.grecaptcha-badge { visibility: hidden;
}
