:root {
  --section-header-height: 4.5rem;
}

.header {
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-areas: "before title after";
  grid-template-columns: min-content auto min-content;
  align-items: center;
  min-height: var(--section-header-height);
}

@media (--device-desktop) {
  .contrastColor {
    --icon-text-color: var(--page-title-text-color);

    color: var(--page-title-text-color);
  }
}

.title {

  @mixin headerFontH5;

  flex: 1;
  grid-area: title;
  min-width: 0;
}

.titleWrapper {

  @mixin textOverflow;

  display: block;
}

.before {
  grid-area: before;
}

.after {
  grid-area: after;
}

@media (--device-mobile) {
  :root {
    --section-header-height: 3.5rem;
  }

  .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    grid-template-areas: "before title after";
    grid-template-columns: minmax(3rem, min-content) 1fr minmax(3rem, min-content);
    grid-column-gap: var(--space-sm);
    width: 100%;
    min-height: calc(var(--section-header-height));
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 var(--space-xxxs);
    border-bottom: var(--border-width) solid var(--divider-color);
    background-color: var(--primary-background-color);
    color: var(--primary-text-color);
  }

  .title {

    @mixin headerFontH6;

    text-align: center;
  }
}

@media print {
  .header {
    position: absolute;
  }
}
