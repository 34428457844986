.bannerContent {
  --right-banner-max-width: 20rem;

  max-width: var(--right-banner-max-width);
  padding: var(--space);
}

div[widget-zone].bannerContent {
  flex-shrink: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  & > div[class="widget-container"] {
    flex-grow: 0;
  }
}

div[widget-zone].bannerContent:empty {
  padding: 0;
}

.rightSize {
  padding-left: 0;
}
