.dropDownList {
  position: relative;
}

.dropDownButton {
  --button-color: inherit;
  --button-background-color: var(--system-black);

  min-width: calc(2 * var(--space-xs) + var(--base-line-height));
  font-weight: var(--font-weight-normal);
}

.menu {
  min-width: 15rem;
}

.isNotLoggedIn {
  > button {
    --button-color: var(--login-header-localization-button-color);
    --icon-text-color: var(--login-header-localization-button-color);
  }
}

@media (--device-desktop) {
  [data-stable-name="UserBar"] {
    .isNotLoggedIn,
    .loggedIn {
      > button {
        --button-color: var(--header-menu-icon-color);

        width: calc(2 * var(--space-xs) + var(--base-line-height));
        min-width: calc(2 * var(--space-xs));
        height: calc(2 * var(--space-xs) + var(--base-line-height));
        min-height: calc(2 * var(--space-xs));
        border-radius: var(--border-radius-circle);
      }

      .label {
        display: none;

        + span {
          display: none;
        }
      }
    }

  }
}

@media (--device-mobile) {
  .loggedIn {
    > button {
      --button-color: var(--primary-accent-color);
    }
  }
}
