.title {

  @mixin headerFontH6;
}

.verificationDescription {
  margin-top: 0;
}

.description {
  margin-top: var(--space-xxs);
}

.link {
  white-space: nowrap;
}
