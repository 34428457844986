.cardWrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  /* to keep spinner wrapper visual height close to card wrapper background while content reload */
  min-height: 280px;
  padding: var(--space-lg) var(--space);
  
  &.smallCard {

    /* need to subtract the .5rem (--space-xxs) to match the width of the default input field width (MPH-55289) */
    width: calc(var(--dialog-width-sm) - var(--space-xxs));
  }

  &.largeCard {
    width: var(--dialog-width-lg);
  }

  &.fullCard {
    width: 100vw;
    box-shadow: none;

    &.roundCorners {
      border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    }
  }

  &.noRoundCorners {
    border-radius: 0;
  }
}

.content,
.content:focus {
  outline: none;
}
