.container {
  box-sizing: border-box;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding-right: var(--space);
  padding-left: var(--space);
}

@media (width <= 767px) {
  .container {
    margin: 0;
    padding: 0;
  }
}

/* TODO: Add breakpoints */
