.layout {
  max-width: unset;
  padding-right: 0;
  padding-left: 0;
}

.empty {
  display: none;
}

div[widget-zone].bannerContent {
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
