.overlay {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
}

.centerAlign {
  align-items: center;
  padding: var(--space) 0;
}

.bottomAlign {
  align-items: flex-end;
  padding: var(--space) 0 0 0;
}

.noAlign {
  align-items: normal;
  padding: 0;
}

.dialog {
  padding: var(--space-xxs) var(--space) var(--space-lg) var(--space);
  text-align: center;
}

.dialogDesktop {
  width: var(--error-dialog-size);
}

.dialogMobile {
  flex-grow: 1;
  width: unset;
  border-radius: var(--border-radius-xxl) var(--border-radius-xxl) 0 0;
}

.dialogMobileAndFrame {
  flex-grow: 1;
  width: unset;
  border-radius: 0;
}

.status {
  padding: var(--space-lg) 0 var(--space-xxs) 0;
}

.title {

  @mixin headerFontH5;

  flex-shrink: 0;
  overflow: hidden;
  margin: 0 auto;
  padding: var(--space-sm) 0;
  text-overflow: ellipsis;
  white-space: normal;
}

.message {
  padding-bottom: var(--space-xxs);
}

.toggleDetailsButton {
  cursor: pointer;
}

.details {

  @mixin baseFontXs;

  padding: var(--space-xxs);
  border-radius: var(--border-radius-sm);
  background: var(--secondary-background-color);
  color: var(--secondary-text-color);
  text-align: left;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
