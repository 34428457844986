.frame {
  min-width: calc(var(--dialog-width-sm) - (var(--space) *2));
  max-width: 100%;
  min-height: calc(var(--space) * 2);
  max-height: 100%;
  border: none;
}

.overlay {
  border-radius: var(--border-radius-lg);
  background-color: var(--primary-background-color);

  @media (--device-mobile) {
    border-radius: var(--border-radius-xxl);
  }
}
