.tabPanelHidden {
  display: none;
}

.tabPanel {
  z-index: var(--z-index-min);
}

@media (--gt-device-mobile) {
  .tabPanel {
    padding: var(--space) 0;
  }
}

@media (--device-mobile) {
  .tabPanel {
    padding: var(--space-xs) 0;
  }
}
