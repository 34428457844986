.wrapper {
  margin: 0 var(--space);
  padding-bottom: var(--space-sm);
  border-bottom: 1px solid var(--divider-color);
}

.content {
  overflow-y: auto;

  /* 60px - minimal allowed heigth for content visibility */
  min-height: 60px;

  /* 330px - all spacings around content container */
  max-height: calc(100vh - 330px);
  outline: none;
}

.formControl {
  margin: var(--space) 0 0 0;
  padding: 0 var(--space);

  & > [class*="formfieldassistive_error"] {
    padding-left: 0;
  }
}

@media (--device-mobile) {
  .content {

    /* 300px - static max-height on mobile devices */
    max-height: 300px;
  }
}
