.container {
  text-align: center;
}

.wizardTitle {

  @mixin headerFontH5;
}

.title {
  margin-bottom: var(--space);
}

.body {
  margin: var(--space-sm) 0 var(--space-lg) 0;
}
