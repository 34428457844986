.menu {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  padding: 0;
  border-radius: 0;
}

.header {
  border-bottom: var(--border-width) solid var(--divider-color);
}

.title {

  @mixin headerFontH6;

  flex-grow: 1;
  text-align: center;
}

.bell {
  color: var(--icon-text-color);
}

.scrollContainer {
  overflow: auto;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: var(--space) var(--space) var(--space-sm) var(--space);

  .label {

    @mixin headerFontH6;

    word-break: break-all;
  }
}

.divider {
  margin: 0 var(--space-xxs);
}

.accordion {
  display: block;
  padding: 0 var(--space-xxs);

  & [role="group"] {
    padding-bottom: var(--space-xxs);

    & > div > div {
      &::after {
        display: none;
      }
    }
  }
}

.section {
  flex-basis: 1rem;
  padding-bottom: var(--space-xxs);
}

.textLink {
  margin-bottom: var(--space-xxs);
}
