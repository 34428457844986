.field {
  margin-bottom: var(--space);

  /* hack for MPH-56865; TODO: find a better solution; */
  &Checkbox {
    [class*="formfieldassistive_error"] {
      padding-left: 0;
    }
  }
}
