:root,
[data-theme="light"],
[data-theme="dark"] {
  --header-background-color: var(--primary-background-color);
}

.header {
  --header-logo-width: 440px;
  --header-logo-slogan-width: 200px;
  --header-border-top-weight: 8px;

  position: relative;
  border-top: var(--header-border-top-weight) solid var(--primary-accent-color);
  border-bottom: var(--border-width) solid var(--divider-color);
  background-color: var(--header-background-color);
}

.container {
  padding-top: var(--space);
}

.menuHeader {
  background-color: var(--top-menu-background-color);
}

.menuContainer {
  padding-top: 0;
}

.navBar {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-start;
  padding-bottom: var(--space);
}

.navBarMenu {
  justify-content: flex-end;
}

.navBarMenuButton {
  color: var(--primary-text-color);
}

.logoZone {
  min-height: calc(3 * var(--base-font-size));

  div[class="widget-container"] {
    flex-grow: unset;
  }
}

.scsoBanner {
  background-color: var(--system-purple);
  color: var(--contrast-text-color);
}

.nonAuthHeader {
  display: flex;
  justify-content: flex-end;
  padding: var(--space-xxs) var(--space-xs);
}

[data-theme="dark"] .scsoBanner {
  color: var(--contrast-text-color);
}
