.userBar {
  display: flex;
  justify-content: flex-end;
}

.preferredName {
  color: var(--header-menu-text-color);
  overflow-wrap: anywhere;
}

.icon svg {
  color: var(--header-menu-icon-color);
}
