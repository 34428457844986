.layout {
  --main-content-background-color: var(--secondary-background-color);
  --main-layout-content-padding: var(--space);
  --layout-section-margin: calc((100% - var(--container-max-width)) / 2 - var(--main-layout-content-padding));

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
  max-width: unset;
  padding: 0;
  background-color: var(--main-content-background-color);
}

.withBackgroundImage {
  --main-content-background-color: var(--bg-img-overlay-opacity-twozone-right, rgb(0 0 0 / 0%));

  color: var(--contrast-text-color);
}

[data-theme="dark"] {
  .withBackgroundImage {
    color: var(--primary-text-color);

    .backButton {
      --icon-text-color: var(--system-white);
    }
  }
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
}

.horizontal {
  display: flex;
  flex-grow: 1;
  flex-basis: var(--container-max-width);
  flex-direction: row;
  flex-wrap: nowrap;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
}

.vertical {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
  padding: var(--space);
}

.leftPadding {
  flex-grow: 1;
  flex-shrink: 5;
  flex-basis: var(--layout-section-margin);
}

.rightPadding {
  flex-grow: 1;
  flex-shrink: 5;
  flex-basis: var(--layout-section-margin);
}

.header {
  margin-top: calc(var(--space) * -1);
}

@media (--gt-device-mobile) {
  .bannerZone {
    padding-right: 0;
    padding-left: 0;
  }
}

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
}

.backButton {
  --icon-text-color: var(--contrast-text-color);

  margin-right: var(--space-sm);
}

@media (--device-mobile) {
  .layout {
    margin: var(--section-header-height) 0 0;
  }

  .withoutHeaderSection {
    margin-top: 0;
  }

  .vertical {
    padding: 0;
  }

  .header {
    margin-top: 0;
  }

  .content {
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 0;
  }

  .backButton {
    --icon-text-color: var(--secondary-text-color);

    margin-right: 0;
  }
}
