main.mainZone {
  --main-background-color: var(--secondary-background-color);
  --main-background-image: var(--i-back-login);
  --main-background-shade-color: var(--bg-img-overlay-opacity-singlezone, rgb(0 0 0 / var(--opacity-lg)));

  /*
  * Make content relative to show popups.
  */
  position: relative;
  display: flex;

  /* Stretch to whole height */
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

/* Change background image for dashboard layout. */
main.mainZone.dashboard {
  --main-background-image: var(--i-back-dashboard);
}

/*
* Background image is fixed in the viewport so that it doesn't move when
* the content's height is greater than the image's height.
* CSS rule "background-attachment: fixed" is inefficient. I use alternative solution.
*/
main.mainZone::before {
  content: "";
  position: fixed;
  top: 0;
  right: var(--hiddenScrollbarWidth, 0);
  bottom: 0;
  left: 0;
  z-index: -1;

  /* Background color */
  background-color: var(--main-background-color);

  /* Location of the image */
  background-image: var(--main-background-image);

  /* Background image doesn't tile */
  background-repeat: no-repeat;

  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;

  /* This is what makes the background image rescale based
    on the container's size */
  background-size: cover;
}

main.mainZone.shade::after {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  /* Background color */
  background-color: var(--main-background-shade-color);
}

main.mainZone div[widget] {
  display: flex;

  /* Stretch to whole height */
  flex-grow: 1;
  flex-direction: row;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
}

@media (--device-mobile) {
  main.mainZone::before {
    position: fixed;
    background-attachment: fixed;
  }
}
