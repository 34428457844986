.formField > * {
  width: 100%
}

.pinField {
  margin: 0 auto;
}

.pinFieldInitialMargin {
  margin: initial;
}