.navSectionContainer {
  display: inline-flex;
  flex: 18.375rem 0 1;
  flex-direction: column;
  padding: 0;
}

.title {
  flex-grow: 0;
  min-height: calc(2 * var(--space-xs) + var(--base-line-height));
  padding: 0 var(--space-xs);
  font-weight: var(--font-weight-bold);
}

.menu {
  max-height: none;
}

.menuItem, .title {
  color: var(--menu-text-color);

  & [class*="icon"] {
    color: var(--menu-icon-color);
  }
}
