.paddings {
  padding: 0;
}

.container {
  display: flex;
  justify-content: space-between;
}

.mainNav {
  display: inline-flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
