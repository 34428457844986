.spinner {
  text-align: center;
}

.tabButton {
  color: var(--menu-text-color);
}

.selectedTab {
  color: var(--primary-accent-color);

  & > span[class*="tabListButtonIcon"] {
    color: var(--primary-accent-color);
  }
}

.tabPanel {
  position: absolute;
  right: 0;
  left: 0;
  z-index: var(--z-index-md);
  padding: var(--space);
  border-top: var(--border-width) solid var(--divider-color);
  border-bottom: var(--border-width-lg) solid var(--primary-accent-color);
  background: var(--top-menu-background-color);
}

.hiddenTab {
  display: none;
}
