.container {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}
