:root,
[data-theme="light"],
[data-theme="dark"] {
  --footer-background-color: var(--primary-background-color);
}

.container {

  @mixin baseFontSm;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space) var(--space-sm);
  border: none;
  border-radius: 0;
  background-color: var(--footer-background-color);
  color: var(--secondary-text-color);
  box-shadow: none;

  & > *:not(:first-child) {
    margin-top: var(--space-sm);
  }
}

.linkItem {
  display: flex;
}

.icon {
  color: var(--footer-menu-icon-color);
}

.link {
  display: inline-flex;
  align-items: center;
  color: var(--footer-menu-text-color);

  & > span:not(:first-child) {
    margin-left: var(--space-xxs);
  }
}

.hidden {
  display: none;
}

[widget-zone="Footer"]:empty {
  display: none;
}

.divider {
  height: var(--space);
  margin: 0 var(--space-sm);
}

.menuLine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

/* reset updateQuickLaunchGap for print version */

@media print {
  .footer {
    margin-bottom: 0!important;
  }
}
