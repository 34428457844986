:root,
[data-theme="light"],
[data-theme="dark"] {
  --top-menu-background-color: var(--primary-background-color);
}

.tabList {
  --tablist-button-height: var(--field-height-xl);

  scroll-behavior: smooth;
  position: relative;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: var(--top-menu-background-color);

  @media (--device-mobile) {
    height: calc(var(--tablist-button-height) + var(--space-sm));
  }
}

.tabContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;

  @media (--device-mobile) {

    /* Fix for Safari in iOS 6,7 */
    position: absolute;
  }
}

.selectedTabContainer {
  border-color: var(--divider-color);
}

.tabListButton {

  @mixin btnReset;

  @mixin resetOutline;

  display: inline-flex;
  height: var(--tablist-button-height);
  padding: 0 var(--space-sm);
  border: var(--border-width-lg) solid transparent;
  background: none;
  color: var(--secondary-text-color);
  line-height: var(--tablist-button-height);
  cursor: pointer;
}

.tabListButtonActive {
  color: var(--primary-accent-color);
}

.tabListButtonLabel {
  padding: 0 var(--space-xxs);
  white-space: nowrap;
}

.tabListButtonLabel:first-child {
  padding-left: 0;
}

.tabListButtonLabel:last-child {
  padding-right: 0;
}

.tabListButton:hover {
  color: var(--primary-accent-color);

  & .tabListButtonIcon {
    color: var(--primary-accent-color);
  }
}

.tabListButton:hover .tabButtonBadge {
  --badge-color: var(--primary-accent-color);
}

.tabListButtonActive .tabListButtonIcon {
  color: inherit;
}

.tabListButtonWidth {
  justify-content: center;
}

.tabListButtonWidth .tabListButtonLabel {

  @mixin textOverflow;
}

@media (--gt-device-mobile) {
  .tabListButton:focus-visible {
    border-color: var(--focus-shadow-color);
    border-radius: var(--border-radius-sm);
    outline: none;
    transition: all var(--animation-duration) ease-in-out;
  }

  .tabListButton:hover {
    color: var(--primary-accent-color);
  }

  .tabListButton:hover .tabButtonBadge,
  .tabButtonBadge {
    --badge-color: var(--primary-accent-color);
  }
}
