body {

  /* Error dialog size */
  --error-dialog-size: 36rem;
  --container-max-width: 79.76rem;

  min-height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}

div.widget-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
}

div.widget-container > div {
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
}

div.widget-size-100 {
  width: 100%;
}

div.widget-body {
  display: flex;

  /* Stretch to whole height */
  flex-grow: 1;
  flex-direction: column;

  /* prevents content overflow and its possible apply overflow: hidden */
  min-width: 0;
}

main div.field-name-html-widget-content {

  /* Stretch to whole height for custom pages */
  flex-grow: 1;
}

div[widget-zone] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-sm);
  align-items: baseline;
  overflow: hidden;
}


@media print {
  html, body {
    width: 100%;
    height: 100%;
  }
}
