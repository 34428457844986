.listItem {
  --divider-style: 1px solid var(--divider-color);

  border-top: var(--divider-style);
  border-radius: var(--border-radius-zero);

  &:first-child {

    /* Border should exist to maintain item height */
    border-top-color: transparent;
  }

  &:last-child {
    border-bottom: var(--divider-style);
  }
}

.field > span[class*="inputContainer"] {
  width: 100%;
}
