.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    border-bottom: var(--border-width) solid var(--divider-color);
  }
}

.listItem {
  border-radius: var(--border-radius-sm);

  &,
  &:focus,
  &:hover {
    & [class*="background"]:not([class*="selected"], [class*="disabled"]) {
      background-color: unset;
    }
  }

  &:active {
    & [class*="background"]:not([class*="selected"], [class*="disabled"]) {
      background-color: var(--system-grey-6);
    }
  }

  &:focus-visible {
    box-shadow: var(--focus-shadow);
    outline: none;
    transition: box-shadow var(--animation-duration) ease-in-out;
  }
}

[data-theme="dark"] {
  .listItem {
    &:active {
      & [class*="background"]:not([class*="selected"], [class*="disabled"]) {
        background-color: var(--system-grey-5);
        opacity: 1;
      }
    }
  }
}

.textItem {
  --item-margin: calc((var(--space-xs) + var(--icon-width) + var(--space-xxxs)) * var(--item-level));

  align-self: flex-start;
  margin-left: var(--item-margin);
  color: var(--menu-text-color);

  & [class*="icon"] {
    color: var(--menu-icon-color);
  }
}

.groupItem {
  font-weight: var(--font-weight-bold);
}

.itemHidden {
  display: none;
}

.empty {
  display: none;
}
