.overlayMask {
  > [class*="overlay__overlay_backdrop"] {
    z-index: var(--z-index-sm);
  }
}

.dialog {

  /* 2rem is the height of loader */
  min-height: calc(2rem + (var(--space)));

  @media (--device-desktop) {
    min-width: var(--dialog-width-sm);
  }
}

.marketingDialog {
  flex-direction: row;
  max-width: calc(100vw - (var(--space) * 2));
  max-height: calc(100vh - (var(--space) * 2));

  @media (--device-mobile) {
    max-width: 100vw;
    max-height: 100vh;
  }

  & [class*="dialog__dialog_paddedContent"] {
    padding: 0 var(--space) var(--space);
  }

  & [class*="dialog__dialog_content"] {
    flex-direction: row;

    &::after {
      display: none;
    }
  }
}

.disclosuresDialog {
  & [class*="dialog__dialog_content"] {
    padding: 0;

    &::after {
      display: none;
    }
  }
}
