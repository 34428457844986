/* Syntax for scrollbar styling has been updated per new specification,
  effective as of Chrome 121.
  https://developer.chrome.com/docs/css-ui/scrollbar-styling
*/
.custom-scrollbar {
  --scrollbar-thumb-color: var(--system-grey-3);
  --scrollbar-track-color: transparent;
  --scrollbar-track-size: 0.25rem;

  overflow: auto;
}

/* Modern browsers with `scrollbar-*` support */

@supports (scrollbar-width: thin) {
  .custom-scrollbar {
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    scrollbar-width: thin;
  }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */

@supports selector(-webkit-scrollbar) {
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  .custom-scrollbar::-webkit-scrollbar {
    max-width: var(--scrollbar-track-size);
    max-height: var(--scrollbar-track-size);
  }
}
