.link {
  display: flex;
  align-items: baseline;
}

.link .linkText {
  white-space: normal;
}

.link .angleRightIcon {
  color: var(--link-text-color);
}

.link:visited .angleRightIcon {
  color: var(--system-purple);
}

.link .angleRightIcon svg {
  fill: var(--link-text-color);
}

.link:visited .angleRightIcon svg {
  fill: var(--system-purple);
}
