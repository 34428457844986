.title {

  @mixin headerFontH6;

  margin-bottom: var(--space);
}

.description {
  margin: 0;
  color: var(--primary-text-color);
}

.content {
  margin-top: var(--space-xs);
}
