:root,
[data-theme="light"],
[data-theme="dark"] {
  --menu-icon-color: var(--primary-accent-color);
  --menu-text-color: var(--primary-text-color);
  --header-menu-icon-color: var(--system-grey-1);
  --header-menu-text-color: var(--primary-text-color);
  --login-header-localization-button-color: var(--system-white);
  --footer-menu-icon-color: var(--system-grey-1);
  --footer-menu-text-color: var(--system-blue);
  --page-title-text-color: var(--system-white);
  --positive-transaction-color: var(--system-green);
  --negative-transaction-color: var(--primary-text-color);
}
