.container {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

@define-mixin gapFallbackClass $size, $gap {
  .$(size)GapFallback {
    &.horizontalGapFallback {
      & > *:not(:last-child) {
        margin-right: $(gap);
      }
    }

    &.horizontalReverseGapFallback {
      & > *:not(:first-child) {
        margin-right: $(gap);
      }
    }

    &.verticalGapFallback {
      & > *:not(:last-child) {
        margin-bottom: $(gap);
      }
    }

    &.verticalReverseGapFallback {
      & > *:not(:first-child) {
        margin-bottom: $(gap);
      }
    }
  }
}

@mixin gapFallbackClass extraSmall, var(--space-xxs);

@mixin gapFallbackClass small, var(--space-sm);

@mixin gapFallbackClass default, var(--space);

@mixin gapFallbackClass large, var(--space-lg);

@mixin gapFallbackClass extraLarge, var(--space-xl);
