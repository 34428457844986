.alert {
  position: fixed;
  top: var(--space-sm);
  right: 0;
  left: 0;
  z-index: var(--z-index-md);
  display: flex;
  justify-content: center;
  align-items: center;
}
