.bannerContent {
  max-width: var(--container-max-width);
  padding: var(--space);
}

div[widget-zone].bannerContent {
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

div[widget-zone].bannerContent:empty {
  padding: 0;
}

.headerZone, .preMiddleZone {
  padding-bottom: 0;
}

.footerZone, .postMiddleZone {
  padding-top: 0;
}

.preMiddleZone > div, .postMiddleZone > div {
  width: 100%;
}

@media (--device-mobile) {
  .bannerContent {
    padding: var(--space-sm);
  }
}
