.htmlWidget > div {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-width: 0;
}

.closeButton {
  position: absolute;
  top: var(--space-xxxs);
  right: var(--space-xxxs);
  z-index: var(--z-index-min);
}